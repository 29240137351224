<template>
	<div class="lac_addText">
		<div class="child-top lac_detail_top">
			<div class="input-from lac_detail_top_info">
				<el-avatar :size="50">
					<img :src="personObj.img" />
				</el-avatar>
				<span>姓名:</span>{{personObj.name}} <span>性别:</span>{{personObj.sex == 1 ? '男' : '女'}}<span>身高:</span>{{personObj.height}}cm <span>体重:</span>{{personObj.weight}}kg <span>所属运动队:</span>{{personObj.sport_team}} <span>部门:</span>{{personObj.department_name}}
			</div>
			<div class="lac_detail_top_btn">
				<span
					class="fanhui"
					@click="
						() => {
							$router.push({ path: '/textItem/lactateTextList',query :{
								img: personObj.img,
								name: personObj.name,
								sex: personObj.sex,
								old: personObj.old,
								height: personObj.height,
								weight: personObj.weight,
								sport_team: personObj.sport_team,
								department_name: personObj.department_name
							} })
						}
					"
					><i class="iconfont iconfanhui"></i>返回</span
				>
			</div>
		</div>
		<div class="content_title"><i class="iconfont icona-5"></i> 测试数据</div>
		<div class="content_info">
			<ul>
				<li><span>标 题</span> {{ infoObj.title }}</li>
				<li><span>测试日期</span> {{ infoObj.date }}</li>
				<li><span>训练类型</span> {{ infoObj.type }}</li>
				<li><span>训练内容</span> {{ infoObj.content }}</li>
			</ul>
			<div class="btn_view">
				<el-button class="save_btn" @click="save" operation-button round>保存</el-button>
				<el-button class="cancel_btn" operation-button round>取消修改</el-button>
			</div>
		</div>
		<div class="content_botView">
			<div id="botView_echarts"></div>
			<div>
				<el-table :data="tableData2" style="width: 95%" stripe>
					<el-table-column prop="date" label="序号" align="center">
						<template slot-scope="scope">
							{{ scope.$index + 1 }}
						</template>
					</el-table-column>
					<el-table-column prop="type" label="2000m皮艇（s）" align="center"> </el-table-column>
					<el-table-column prop="value" label="血乳酸值 (mmol/L)" align="center"> </el-table-column>
					<el-table-column prop="time" label="血乳酸测试时间" align="center"> </el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableData2: [],
			infoObj: {},
			echartsData: [],
			xdata: [],
			ydata: [],
			ydata1: [],
			personObj: {}
		}
	},
	mounted() {
		this.personObj = {
			name: this.$route.query.name,
			sex: this.$route.query.sex,
			old: this.$route.query.old,
			height: this.$route.query.height,
			weight: this.$route.query.weight,
			img: this.$route.query.img,
			sport_team: this.$route.query.sport_team,
			department_name: this.$route.query.department_name
		}
		if (this.$route.query.type == 1) {
			this.infoObj = {
				title: '男子皮艇2000米测试',
				date: '2023-08-15',
				type: '持续训练',
				content: '2000米皮艇',
			}
			this.tableData2[0] = { type: '502.4', value: '7.49', time: '13：00' }
			this.xdata = ['13:00']
			this.ydata = ['502.4']
			this.ydata1 = ['7.49']
		} else {
			this.infoObj = {
				title: '男子皮艇2000米测试',
				date: '2023-09-10',
				type: '持续训练',
				content: '2000米皮艇',
			}
			this.tableData2[0] = { type: '498.5', value: '7.83', time: '14：00' }
			this.xdata = ['14:00']
			this.ydata = ['498.5']
			this.ydata1 = ['7.83']
		}
		this.$nextTick(() => {
			this.setBarEcharts()
		})
	},
	methods: {
		save() {
			this.$router.push({ path: '/textItem/lactateTextList',query :{
								img: this.personObj.img,
								name: this.personObj.name,
								sex: this.personObj.sex,
								old: this.personObj.old,
								height: this.personObj.height,
								weight: this.personObj.weight,
								sport_team: this.personObj.sport_team,
								department_name: this.personObj.department_name
							}  })
		},
		setBarEcharts() {
			let leftserum = this.$echarts.init(document.getElementById('botView_echarts'))
			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '5%',
					right: '10%',
					bottom: '3%',
					top: '15%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: this.xdata,
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '2000m皮艇（s）',
						min: 0,
						axisLabel: {
							formatter: '{value}',
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
					{
						type: 'value',
						name: '血乳酸值（mmol/L）',
						nameLocation: 'end',
						min: 0,
						axisLabel: {
							formatter: '{value}',
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				series: [
					{
						name: '',
						type: 'bar',
						barWidth: 24,
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#73CFFF', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#3FA1FF', // 100% 处的颜色
								},
							],
						},
						data: this.ydata,
					},
					{
						name: '',
						type: 'line',
						yAxisIndex: 1,
						color: '#FFB835',
						lineStyle: {
							width: 3,
						},
						symbol: 'circle',
						symbolSize: 12,
						data: this.ydata1,
					},
				],
			}
			leftserum.setOption(options)
			this.$echartsResize(leftserum)
		},
	},
}
</script>

<style lang="scss" scoped>
.lac_addText {
	.lac_detail_top {
		background: #fff;
		border-radius: 5px;
		padding: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.lac_detail_top_info {
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #333333;
			span {
				margin: 0 5px 0 20px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
		.lac_detail_top_btn {
			.new_btn {
				background: #1a9f16;
				color: #fff;
				border: none;
				margin-right: 20px;
			}
			.fanhui {
				font-size: 16px;
				color: #666;
				display: inline-block;
				padding-top: 5px;
				margin-left: 40px;
				margin-right: 20px;
				i {
					margin-right: 5px;
					color: #000;
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	.content_title {
		font-size: 17px;
		font-weight: 500;
		color: #333333;
		position: relative;
		background: #fff;
		padding: 15px;
		i {
			color: #0055e9;
			margin-right: 5px;
		}
	}
	.content_info {
		display: flex;
		align-items: center;
		background: #fff;
		padding-top: 10px;
		ul {
			width: 70%;
			display: flex;
			li {
				width: 24%;
				margin-left: 2%;
				border-radius: 10px;
				border: 1px solid #3fa1ff;
				height: 45px;
				font-weight: 600;
				color: #73879c;
				> span {
					width: 80px;
					display: inline-block;
					background: #f1f7ff;
					border-radius: 10px 0px 0px 10px;
					border-right: 1px solid #3fa1ff;
					font-weight: 600;
					color: #73879c;
					height: 45px;
					line-height: 45px;
					text-align: center;
					font-size: 16px;
				}
			}
		}
		.btn_view {
			width: 30%;
			text-align: right;
			.el-button {
				width: 120px;
				margin-right: 20px;
			}
			.save_btn {
				background: #0055e9;
				color: #fff;
				border-color: #0055e9;
			}
			.cancel_btn {
				border-color: #0055e9;
				color: #0055e9;
			}
		}
	}
	.content_botView {
		background: #fff;
		padding-top: 20px;
		display: flex;
		> div {
			flex: 1;
			height: 400px;
			// padding: 20px;
			box-sizing: border-box;
			.el-table {
				border: 1px solid #ececec;
				border-radius: 10px;
				margin-top: 15%;
				// margin-right: 20px;
				/deep/ .el-table__header > thead > tr > th {
					background: #f1f7ff;
					font-weight: 600;
					color: #73879c;
				}
			}
		}
	}
}
</style>
