var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lac_addText"},[_c('div',{staticClass:"child-top lac_detail_top"},[_c('div',{staticClass:"input-from lac_detail_top_info"},[_c('el-avatar',{attrs:{"size":50}},[_c('img',{attrs:{"src":_vm.personObj.img}})]),_c('span',[_vm._v("姓名:")]),_vm._v(_vm._s(_vm.personObj.name)+" "),_c('span',[_vm._v("性别:")]),_vm._v(_vm._s(_vm.personObj.sex == 1 ? '男' : '女')),_c('span',[_vm._v("身高:")]),_vm._v(_vm._s(_vm.personObj.height)+"cm "),_c('span',[_vm._v("体重:")]),_vm._v(_vm._s(_vm.personObj.weight)+"kg "),_c('span',[_vm._v("所属运动队:")]),_vm._v(_vm._s(_vm.personObj.sport_team)+" "),_c('span',[_vm._v("部门:")]),_vm._v(_vm._s(_vm.personObj.department_name)+" ")],1),_c('div',{staticClass:"lac_detail_top_btn"},[_c('span',{staticClass:"fanhui",on:{"click":() => {
						_vm.$router.push({ path: '/textItem/lactateTextList',query :{
							img: _vm.personObj.img,
							name: _vm.personObj.name,
							sex: _vm.personObj.sex,
							old: _vm.personObj.old,
							height: _vm.personObj.height,
							weight: _vm.personObj.weight,
							sport_team: _vm.personObj.sport_team,
							department_name: _vm.personObj.department_name
						} })
					}}},[_c('i',{staticClass:"iconfont iconfanhui"}),_vm._v("返回")])])]),_vm._m(0),_c('div',{staticClass:"content_info"},[_c('ul',[_c('li',[_c('span',[_vm._v("标 题")]),_vm._v(" "+_vm._s(_vm.infoObj.title))]),_c('li',[_c('span',[_vm._v("测试日期")]),_vm._v(" "+_vm._s(_vm.infoObj.date))]),_c('li',[_c('span',[_vm._v("训练类型")]),_vm._v(" "+_vm._s(_vm.infoObj.type))]),_c('li',[_c('span',[_vm._v("训练内容")]),_vm._v(" "+_vm._s(_vm.infoObj.content))])]),_c('div',{staticClass:"btn_view"},[_c('el-button',{staticClass:"save_btn",attrs:{"operation-button":"","round":""},on:{"click":_vm.save}},[_vm._v("保存")]),_c('el-button',{staticClass:"cancel_btn",attrs:{"operation-button":"","round":""}},[_vm._v("取消修改")])],1)]),_c('div',{staticClass:"content_botView"},[_c('div',{attrs:{"id":"botView_echarts"}}),_c('div',[_c('el-table',{staticStyle:{"width":"95%"},attrs:{"data":_vm.tableData2,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"date","label":"序号","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"2000m皮艇（s）","align":"center"}}),_c('el-table-column',{attrs:{"prop":"value","label":"血乳酸值 (mmol/L)","align":"center"}}),_c('el-table-column',{attrs:{"prop":"time","label":"血乳酸测试时间","align":"center"}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_title"},[_c('i',{staticClass:"iconfont icona-5"}),_vm._v(" 测试数据")])
}]

export { render, staticRenderFns }